









































































































import { PageBase } from "@/core/models/shared";
import { NfeService } from "@/core/services/dfe";
import { StatusDfeService } from "@/core/services/geral";
import { Component, Watch} from "vue-property-decorator";

@Component
export default class ListaNfe extends PageBase {
   search: any = '';
   loading: boolean = false;
   totalLista: number = 0;
   lista: any[] = [];
   status: any[] = [];
   options: any = {
      itemsPerPage: -1
   };
   overlay: boolean = false;
   sheet: boolean = false;
   localSelected: any[] = [];
   data = new Date();
   filtro: any = {
      numeroInicial: null,
      numeroFinal: null,
      embarcacaoId: null,
      dataInicial: new Date(this.data.getFullYear(), this.data.getMonth(), 1).toYYYYMMDD(),
      dataFinal: new Date(this.data.getFullYear(), this.data.getMonth() + 1, 0).toYYYYMMDD(),
      statusId: null
   }

    service = new NfeService();
    headers: any[] = [
        { text: '',value:'actions' ,sortable: false },
        { text: 'Série', value: 'serie' },
        { text: 'Número', value: 'numero' },
        { text: 'Emissão', value: 'dataHora' },
        { text: 'Destinatário', value: 'destinatario.nome' },
        { text: 'Chave de Acesso', value: 'chaveAcesso', sortable: false },
        { text: 'Total', value: 'total'},
        { text: 'Status', value: 'status.nome', align: "center"},
    ];

   @Watch('app.emitenteId')
   onApp(){
      this.Atualizar();
   }

   @Watch('options', { deep: true })
   Atualizar(){
      const { page, itemsPerPage, sortBy, sortDesc, search, columns } = this.options;
      this.loading = true;
      //this.service.Listar(page, itemsPerPage, sortBy, sortDesc, this.search, this.headers, undefined, this.app.emitenteId).then(
      this.service.Obter(this.app.emitenteId, this.filtro.dataInicial, this.filtro.dataFinal, this.filtro.numeroInicial, this.filtro.numeroFinal, this.filtro.statusId).then(
      res => {
         this.lista = res.data;
         this.totalLista = res.data.length;
      },
      err => {
         if (!err.response){
               if(!navigator.onLine){
                  this.$swal("Aviso", "Por favor, verifique a internet", "warning");
               }
               else{
                  this.$swal("Aviso", "Não foi possível acessar a API", "error");
               }
         }
         else{
               this.$swal("Aviso", err.response.data, "error");
         }
      })
      .finally(() => (this.loading = false));
   }

   mounted(){
      let statusService = new StatusDfeService();
      statusService.ListarTudo().then(
         res =>{
            this.status = res.data;
            this.status.unshift({ id: null, nome: "TODOS" });
         }
      );
   }

   ObterRetornoConsulta(retornoConsulta: string){

      if (retornoConsulta){
         this.$swal('Retorno da Consulta', retornoConsulta, 'info');
      }

   }

  Danfe(nfe: any){

    this.overlay = true;
    this.service.DanfePdf(this.app.emitenteId, nfe.serie, nfe.numero).then(
        res => {
            let file = new Blob([res.data], {type: 'application/pdf'});
            let fileURL = URL.createObjectURL(file);
            window.open(fileURL);
        },
        err => {
            let byteArray = new Uint8Array(err.response.data);
            let decodedString = String.fromCharCode.apply(String, Array.from(byteArray));
            let decodedString2 = decodeURIComponent(escape(decodedString));

            this.$swal('Aviso', decodedString2, 'warning');
        }
    )
    .finally(() => this.overlay = false);

  }

  async DownloadXml(nfe: any){

    this.overlay = true;
    this.service.DownloadXml(this.app.emitenteId,  nfe.serie, nfe.numero).then(
        res => {

            let header = res.headers['content-disposition'];
            let filename = header.match(/filename[^;\n=]*=((['"]).*?\2|[^;\n]*)/)[1];
            let reader = new FileReader();
            reader.readAsText(res.data, 'UTF-8');
            reader.onload = (arquivo: any) => {
                let element = document.createElement('a');
                element.setAttribute('href', `data:text/xml;charset=utf-8,${encodeURIComponent(arquivo!.target!.result)}`);
                element.setAttribute('download', filename);

                element.style.display = 'none';
                document.body.appendChild(element);

                element.click();

                document.body.removeChild(element);
            };
        },
        err => {
            let byteArray = new Uint8Array(err.response.data);
            let decodedString = String.fromCharCode.apply(String, Array.from(byteArray));
            let decodedString2 = decodeURIComponent(escape(decodedString));

            this.$swal('Aviso', decodedString2, 'warning');
        }
    )
    .finally(() => this.overlay = false);

  }

  async DownloadXmlMultiplo(nfes: any[]){

    const ids = nfes.map(({id}) => id);

    this.overlay = true;
    this.service.DownloadXml2(this.app.emitenteId, ids).then(
        res => {

            const header = res.headers['content-disposition'];
            const filename = header.match(/filename[^;\n=]*=((['"]).*?\2|[^;\n]*)/)[1];

            var url = window.URL.createObjectURL(res.data);
            var a = document.createElement('a');
            a.href = url;
            a.download = filename;
            document.body.appendChild(a);
            a.click();    
            a.remove();
        }
    )
    .finally(() => this.overlay = false);

  }

}

